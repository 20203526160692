import React from 'react'
import * as styles from '../../assets/styles.module.scss'

export default () => (
    <section className={styles.Promise}>
        <section className={styles.Centered}>
            <h1>Our Brand Promise</h1>

            <p className={styles.Subtitle}>
                We pride ourselves to put a lot of love,
                effort and craftmanship to what we do. 
                We will treat your project with passion 
                and dedication.
            </p>

            <div className={styles.Block}>
                <h2>True craftmanship and dedication</h2>
            </div>

            <div className={styles.Block}>
                <h2>Transparent & direct communication</h2>
            </div>

            <div className={styles.Block}>
                <h2>A product designed with your users in mind</h2>
            </div>
        </section>
    </section>
)